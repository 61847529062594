import React from 'react'

const GoToTop: React.FC = () => (
    <div className="gototop js-top">
        <a href="#" className="js-gotop">
            <i className="icon-arrow-up2" />
        </a>
    </div>
)

export default GoToTop
