import * as React from 'react'

type ReadMoreProps = {
    url: string,
    content: string,

    // TODO remove inline styles
    style?: any
}

const ReadMore: React.FC<ReadMoreProps> = (props) => {
    const { url, content, style } = props

    return (
        <p style={style}>
            <a href={url} target="_blank">
                {content}{' '}<i className="icon-arrow-right3" />
            </a>
        </p>
    )
}

export default ReadMore
