import React from 'react';
import './App.css';
import GoToTop from './GoToTop';
import Nav from './Nav';
import Hero from './Hero'

import Intro from './IntroEx';
import Project from './Project';
import Counter from './Counter';
import Services from './Services';
import Blog from './Blog';
import Footer from './Footer';
import Platform, { PlatformProps, ItemProps } from './Platform';
import Laboratories, { LaboratoriesProps } from './Laboratories';
import Specs from './Specs';
import ReadMore from './ReadMore';
import Logo from './Logo'

const introData = {
    title: 'История побед',
    header: {
        primary: 'Морская платформа',
        secondary: 'Федор Ушаков'
    },
    content: 'С момента постройки в 2011 году платформа принимала участие в ряде крупнейших строительных объектах по части изыскательских и строительно-монтажных работ в акватории северных морей Российской Федерации: АрктикСПГ2 (Салмановское месторождение, терминал «Утренний»), Южно-Русское нефтегазовое месторождение, Nord Stream (Северный поток), газопровод через реку Северная Двина.'
}


const platformData: PlatformProps = {
    title: 'база',
    header: {
        primaryLine: 'Морская платформа',
        secondaryLine: 'на базе СМП-03'
    },
    description: (<>
        <p>
            Самоподъёмная платформа (СМП) рассчитана для базирования над волной на глубинах моря от 1,7 до 20 метров и снабжена двухвинтовой винторулевой колонкой мощностью 275 л.с., обеспечивающей движение в пределах объекта со скоростью до 5 узлов.
        </p>
        <p>На палубе имеется 4 буровых колодца (клюза, moon pool) диаметром 400 мм. Позволяют одровременно выполнять работу двум установкам (например буровой и установкой статического зондирования).</p>
        <ReadMore content="Подробное описание (.pdf)" url='#'/>
    </>),
    items: [
        { 
            icon: 'flaticon-engineer',
            title: 'Буровое оснащение',
            content: <>
                { /* 'Платформа оснащена буровыми установками:', */ }
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    <li style={{ position: 'relative', marginTop: 30 }}>
                        <span style={{ display: 'inline-block', position: "absolute", top: 0, left: -30 }}>—</span>
                        <span style={{ color: 'black', fontWeight: 400 }}>Boart Longyear LF90</span><br /> Используется при бурении на глубины свыше 100 м, применяется тройная колонковая труба triple core и грунтоносы Shelby.
                    </li>
                    <li style={{ position: 'relative', marginTop: 15 }}>
                        <span style={{ display: 'inline-block', position: "absolute", top: 0, left: -30 }}>—</span>
                        <span style={{ color: 'black', fontWeight: 400 }}>УРБ 2А2</span><br /> Используется при бурении на глубины до 100 м.
                    </li>
                    <li style={{ position: 'relative', marginTop: 15 }}>
                        <span style={{ display: 'inline-block', position: "absolute", top: 0, left: -30 }}>—</span>
                        <span style={{ color: 'black', fontWeight: 400 }}>Geotech 650 D</span><br /> Используется при бурении на глубины до 50 м. Оснащена двухшпиндельной системой, в том числе СРТ.
                    </li>
                    <li style={{ position: 'relative', marginTop: 15 }}>
                        <span style={{ display: 'inline-block', position: "absolute", top: 0, left: -30 }}>—</span>
                        <span style={{ color: 'black', fontWeight: 400 }}>Установка статического зондирования СРТ FOX-200</span><br /> Используется при глубинах зондирования до 100 м.
                    </li>
                </ul>
            </>
        },
        {
            icon: 'flaticon-engineering',
            title: 'Техническое обеспечение',
            content: 'Платформа снабжена необходимым набором технологических, жилых (20 чел.) и вспомогательных помещений и технологических средств.'
        },
        { 
            icon: 'flaticon-crane',
            title: 'Палубный кран',
            content: 'Судовой кран Liebherr  грузоподьемностю 20 тонн обеспечивает доставку грузов с причалов и других судов на палубу платформы, а также используется для оперативного монтажа гидравлических опор и перестановки буровых станков во время работ на акватории.', },
            { 
                icon: 'flaticon-conveyor',
                title: 'Модульная конструкция',
                content: 'Возможно изменение конфигурации платформы для буксирования по каналам шириной 17,5 м. Также возможна разборка платформы (14 рабочих дней) с целью ее передислокации наземным транспортом.' },
    ]
}

// 42px
// 54px

const laboratoriesData: LaboratoriesProps = {
    title: 'исследования',
    header: {
        primaryLine: 'Категории исследований',
        secondaryLine: 'лабораторное оснащение'
    },
    description: (<>
        <p>Самоподъёмная платформа (СМП) рассчитана для базирования над волной на глубинах моря от 1,7 до 20 метров и снабжена двухвинтовой винторулевой колонкой мощностью 275 л.с., обеспечивающей движение в пределах объекта со скоростью до 5 узлов.</p>
        <ReadMore content="Подробное описание (.pdf)" url='#' />
    </>),
    items: [
        {
            icon: 'flaticon-architect-with-helmet',
            title: 'Набортная экспресс-аналитическая лаборатория',
            content: 'Оперативное получение информации о состоянии и свойствах донных осадков для выделения однородных инженерно-геологических слоев (горизонтов), а также для контроля результатов других контактных и дистанционных методов исследования свойств грунтов.'
        }, {
            icon: 'flaticon-worker',
            title: 'Стационарная инженерно-геологическая лаборатория',
            content: 'Определение состава, состояния, физических, водных и механических свойств грунтов с выделением их классов, групп и разновидностей, с определением нормативных и расчетных характеристик для выделенных однородных горизонтов (инженерно-геологических элементов).'
        },
    ]
}

{ /* http://www.rosstroyexpo.ru/news/stroit/2011/11/spusk-na-vodu-inzhenernogo-morskogo-sudna-fedor-ushakov-sostoyalsya-v-kaliningrade.aspx */ }
{ /* https://www.1tv.ru/news/2011-11-22/107592-v_kaliningrade_spuscheno_na_vodu_inzhenernoe_sudno_platforma_fedor_ushakov */ }
{ /* https://www.moreservis.com/%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B0%D1%8F-%D0%B1%D1%83%D0%BA%D1%81%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B0/%D0%B1%D1%83%D0%BA%D1%81%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B-%D1%84%D1%91%D0%B4%D0%BE%D1%80-%D1%83%D1%88%D0%B0%D0%BA%D0%BE%D0%B2/ */ }

const App: React.FC = () => {
	return (
		<>
			<div className="ui-loader" />

			<div id="page">
				<Nav logo={<Logo />} />

				<Hero />

                <Intro {...introData} />

                <Specs />

                <Counter />

                <Platform {...platformData} />

                <Laboratories {...laboratoriesData} />


				{/* <Services /> */}

				{/* <Blog /> */}

				{/* <About /> */}

				{/* <Testimonials /> */}

				{/* <Subscribe /> */}

				<Footer />
			</div>

			<GoToTop />
		</>
	);
}

export default App;
