import React from 'react'

export type IntroProps = {
    title: string,
    header: {
        primary: string,
        secondary: string
    }
    content: string
}

const imageContainerStyle = {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0',
    paddingBottom: '4em',
}

const Intro: React.FC<IntroProps> = (props) => {
    const { title, header, content } = props

    return (
        <div id="intro" className="ui-light-grey">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-push-4 animate-box ui-heading animate-box">
                        <span className="sm">{title}</span>
                        <h2>
                            <span className="thin">{header.primary}</span>{" "}
                            <span className="thick">{header.secondary}</span>
                        </h2>
                    </div>

                    { /* TODO remove styles from here, make them part of CSS */ }
                    <div className="col-md-4 col-md-pull-4 animate-box">
                        <div className='box' style={imageContainerStyle}>
                        {/* <div className="box text-center">
                            <span className="num">25</span>
                            <span className="yr">Years</span>
                            <span className="thin">Experience</span>
                        </div> */}
                            <img className="img img-responsive" src='/logo.png' />
                        </div>
                    </div>
                    <div className="col-md-4 animate-box">
                        <p>{content}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro