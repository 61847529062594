import * as React from 'react'

const logoStyles = {
    root: {
        background: 'white',
        border: '1px solid green',
        borderRadius: '50%',
        overflow: 'hidden',
        width: '65px',
        boxShadow: '0 0 15px 0px rgba(0,0,0,0.2)',
    },
    image: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
        transform: 'scale(1.3)'
    }
}

const Logo: React.FC = () => {
    return (
        <div style={logoStyles.root}>
            <img style={logoStyles.image} className='img img-responsive' src='/logo.png'/>
        </div>
    )
}

export default Logo