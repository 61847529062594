import React from 'react'

export type Slot = string | React.ReactElement

const renderSlot = (
    slotContent: Slot,
    renderString: (content: string) => string | React.ReactElement
): string | React.ReactElement => {
    return React.isValidElement(slotContent)
        ? slotContent
        : renderString(slotContent as string)
}

export default renderSlot
