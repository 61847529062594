import * as React from 'react'
import renderSlot, { Slot } from './renderSlot';

export type PlatformProps = {
    title: string,
    header: {
        primaryLine: string,
        secondaryLine: string
    },
    description: Slot,
    items: ItemProps[],
}

export type ItemProps = {
    icon: string,
    title: string,
    content: Slot
}

const Item: React.FC<ItemProps> = (props) => {
    const { icon, title: header, content } = props

    return (
        <div className="col-md-6 animate-box">
            <div className="services">
                <span className="icon">
                    <i className={icon} />
                </span>
                <div className="desc">
                    <h3>{header}</h3>
                    {renderSlot(content, asString => <p>{asString}</p>)}
                </div>
            </div>
        </div>
    )
}

const Platform: React.FC<PlatformProps> = (props) => {
    const { title, items, header, description } = props

    const renderItems = (items: ItemProps[]) => {
        return items.reduce((acc, current, index) => {
            if (index % 2 !== 0) {
                return acc
            }

            const nextItem = (index + 1) < items.length
                ? items[index + 1]
                : null

            const rowToAdd = (
                <div className="row" key={index}>
                    <div className="wrap">
                        <Item {...current} />
                        {nextItem && <Item {...nextItem} />}
                    </div>
                </div>
            )

            return [...acc, rowToAdd ]
        }, [])
    }

    return (
        <div id="platform" className="ui-services">
            { /* TODO 'services' id should be renamed, if used for styling */ }
            <div className="container">
                <div className="row">
                    { /* TODO why two 'animate-box classes'? */ }
                    <div className="col-md-4 animate-box ui-heading animate-box">
                        <span className="sm">{title}</span>
                        <h2>
                            <span className="thin">{header.primaryLine}</span>{" "}
                            <span className="thick">{header.secondaryLine}</span>
                        </h2>
                        {renderSlot(description, ifString => <p>{ifString}</p>)}
                    </div>
                    <div className="col-md-8">
                        {renderItems(items)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Platform
