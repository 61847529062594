import React from 'react'

const Footer: React.FC = () => (
    <footer id="footer" role="contentinfo">
        <div className="container">
            <div className="row row-pb-sm">
                <div className="col-md-4 col-md-offset-3 ui-widget">
                    <h4>О компании</h4>
                    <p>
                    ООО «Морские платформы» - судоходная компания, которая предоставляет полный комплекс по судообеспечению объектов строительства любого типа и размера.
            </p>
                    <p />
                    {/* <ul className="social-icons">
                        <li>
                            <a href="#">
                                <i className="icon-twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-linkedin" />
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-dribbble" />
                            </a>
                        </li>
                    </ul> */}
                    <p />
                </div>
                {/* <div className="col-md-3 col-md-push-1">
								<h4>Recent Blog</h4>
								<ul className="ui-footer-links">
									<li>
										<span>— 20 Jan, 2017</span>
										<a href="#">Results of Annual General Meeting</a>
									</li>
									<li>
										<span>— 19 Jan, 2017</span>
										<a href="#">
											Construction was awarded with “The Best Construction
											Company” prize
                </a>
									</li>
									<li>
										<span>— 18 Jan, 2017</span>
										<a href="#">
											New Saint Michael’s College Residence Hall Buzzing
											with Student Activity
                </a>
									</li>
								</ul>
							</div> */}

                
                { /* LINKS */ }
                {/* <div className="col-md-4 col-md-push-1 ui-widget">
                    <h4>Information</h4>
                    <p />
                    <ul className="footer-links">
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Our Company
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Certification
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Our services
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Career
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Core Values
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> Company History
                </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="icon-check" /> FAQ
                </a>
                        </li>
                    </ul>
                    <p />
                </div> */}

                <div className="col-md-4 col-md-push-1">
                    <h4>Контакты</h4>
                    <ul className="footer-links">
                        {/* <li>
                            Belyaevo avenue, <br /> Moscow RU
                        </li> */}
                        <li>
                            <a href="tel:+79296767075">
                                <i className="icon-phone" /> + 7 (929) 676-70-75
                </a>
                        </li>
                        <li>
                            <a href="mailto:info@searigs.ru">
                                <i className="icon-envelope" />{" "}info@searigs.ru
                            </a>
                        </li>
                         <li>
                            <a href="https://yandex.ru/maps/-/CCQpzBaZwA" target="_blank">
                                <i className="icon-location4" /> 117279, г. Москва, м. Беляево, ул. Миклухо-Маклая, д. 36А, офис 501, Торгово-офисный центр «МЦ»
                            </a>
                        </li>

                        {/* <li>
                            <a href="http://luxehotel.com">
                                <i className="icon-location4" /> yourwebsite.com
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <p>
                        {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                        <small className="block">
                            ООО "Морские Платформы" © 2018-2020 
                        {/* <br />This template
                is made with{" "}
                            <i className="icon-heart3" aria-hidden="true" /> by{" "}
                            <a href="#" target="_blank">
                                Kuzhelov
                </a> */}
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer