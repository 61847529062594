import React, { useState } from 'react'
import renderSlot, { Slot } from './renderSlot';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    menuItem: {
        fontSize: '18px'
    }
}

const items: NavItemProps[] = [
    // { content: 'Home', to: '#' },
    { content: 'О платформе', to: '#intro' },
    { content: 'Сезон 2020', to: '#ui-project' },
    { content: 'Оснащение', to: '#platform' },
    { content: 'Контакты', to: '#footer' }
]

type NavItemProps = {
    active?: boolean
    to?: string
    content?: string
    onClick?: JSX.IntrinsicElements['a']['onClick']
}

const NavItem: React.FC<NavItemProps> = ({ to, content, active, onClick }) => {
    return (
        <li className={active ? "active" : "" }>
            <a style={styles.menuItem} href={to} onClick={onClick}>{content}</a>
        </li>
    )
}

export type NavProps = {
    logo?: Slot
}

const Nav: React.FC<NavProps> = (props) => {
    const [activeItemIndex, setActiveItemIndex] = useState(-1)

    const { logo = 'Ushakov' } = props

    return (
        <nav className="ui-nav" role="navigation">
            <div className="top-menu">
                <div className="container">
                    <div className="row" style={styles.root}>
                        <div className="col-xs-2">
                            <div id="ui-logo">
                                <a href="#">
                                    {renderSlot(logo, logoAsString => logoAsString)}
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-10 text-right menu-1">
                            <ul>
                                {items && items.map((item, index) => <NavItem
                                    key={index}
                                    {...item}
                                    active={index === activeItemIndex}
                                    onClick={() => setActiveItemIndex(index)}
                                /> )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav
