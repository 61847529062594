import React from 'react'

type HeroProps = {
    slides: HeroSlideProps[]
}

type HeroSlideProps = {
    header: string,
    subheader: string,
    image: string
}

const styles = {
    root: {
        boxShadow: '0 0 7px 4px rgba(0,0,0,0.3)'
    }
}

const slides = [
    {
        image: 'images/ushakov-new-2.png',
        header: 'Многофункциональная Морская Платформа',
        subheader: 'ФЕДОР УШАКОВ'
    },
    {
        image: 'images/ushakov-new-3.png',
        // image: 'images/ushakov-vessel-2.jpg',
        header: 'Самоподьемная Морская Платформа',
        subheader: 'Комплексные инженерные изыскания'
    },
    {
        // image: 'images/ushakov-2.jpg',
        image: 'images/ushakov-1.jpg',
        header: 'На глубине до 30 метров',
        subheader: 'Строительно-монтажные работы'
    },
]

const HeroSlide: React.FC<HeroSlideProps> = ({ image, header, subheader }) => (
    <li style={{ backgroundImage: `url(${image})` }}>
    <div className="overlay" />
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 col-sm-12 col-md-offset-2 slider-text">
                <div className="slider-text-inner text-center">
                    <h2>{header}</h2>
                    <h1>{subheader}</h1>
                </div>
            </div>
        </div>
    </div>
</li>
)

const HeroEx: React.FC = () => (
    <aside id="ui-hero" style={styles.root}>
        <div className="flexslider">
            <ul className="slides">
                {slides.map(slide => <HeroSlide key={slide.image} {...slide} />)}
            </ul>
        </div>
    </aside>
)

export default HeroEx