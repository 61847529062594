import React from 'react'

const Counter: React.FC = () => (
    <div
        id="ui-counter"
        className="ui-counters"
        style={{ backgroundImage: "url(images/cover_img_1.jpg)" }}
        data-stellar-background-ratio="0.5"
    >
        <div className="overlay" />
        <div className="container">
            <div className="row" />
            <div className="row">
                <div className="col-md-3 text-center animate-box">
                    <span className="icon">
                        <i className="flaticon-worker" />
                    </span>
                    <span
                        className="ui-counter js-counter"
                        data-from={0}
                        data-to={10}
                        data-speed={3000}
                        data-refresh-interval={50}
                    />
                    <span className="ui-counter-label">Опыт работы, лет</span>
                </div>
                <div className="col-md-3 text-center animate-box">
                    <span className="icon">
                        <i className="flaticon-architect-with-helmet" />
                    </span>
                    <span
                        className="ui-counter js-counter"
                        data-from={0}
                        data-to={26}
                        data-speed={3000}
                        data-refresh-interval={50}
                    />
                    <span className="ui-counter-label">Объектов</span>
                </div>
                <div className="col-md-3 text-center animate-box">
                    <span className="icon">
                        <i className="flaticon-crane" />
                    </span>
                    <span
                        className="ui-counter js-counter"
                        data-from={0}
                        data-to={250}
                        data-speed={3000}
                        data-refresh-interval={50}
                    />
                    <span className="ui-counter-label">
                    Грузоподъемность, тонн
            </span>
                </div>
                <div className="col-md-3 text-center animate-box">
                    <span className="icon">
                        <i className="flaticon-engineering" />
                    </span>
                    <span
                        className="ui-counter js-counter"
                        data-from={0}
                        data-to={30}
                        data-speed={3000}
                        data-refresh-interval={50}
                    />
                    <span className="ui-counter-label">Длина ног, метров</span>
                </div>
            </div>
        </div>
    </div>
)

export default Counter