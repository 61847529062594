import * as React from 'react'
import ReadMore from './ReadMore';


const items = [
    {
        header: 'Техническое оснащение',
        description: 'На платформе размещена строительная и инженерно-изыскательская техника для проведения широкого класса работ.',
        image: 'images/ushakov-new-1.jpg',
        to: '#platform'
    },{
        header: 'Исследовательское оборудование',
        description: 'Платформа оборудована зондирующей установкой Geotech 605D (Швеция), буровой установкой МБУ-5 (Россия), набортной инженерно-геологической лабораторией «АСИС», а также средствами пробоотбора.',
        image: 'images/ushakov-new-3.png',
        to: '#laboratories'
    },
    {
        header: 'Установка допоборудования',
        description: 'Для производства гидротехнических работ возможно дооснащение платформы грузовым краном, дноуглубительным экскаватором, сварочным оборудованием, водолазным комплексом.',
        image: 'images/ushakov-new-2.png',
        to: '#'
    },
]

type CarouselItemProps = {
    index?: number,
    header?: string,
    description?: string,
    image?: string,
    to?: string
}

const CarouselItem: React.FC<CarouselItemProps> = (props) => {
    const { index, header, description, image, to } = props

    return (
        <div className="item">
            <span
                className="project"
                style={{
                    backgroundImage: `url(${image})`
                }}
            >
                <div className="desc-t">
                    <div className="desc-tc">
                        <div className="desc">
                            {/* <span className='index'>
                                <small>{index}</small>
                            </span>{" "} */}
                            <div style={{ flex: 1, paddingLeft: '20px' }}>
                                <h3>{header}</h3>
                                <p>{description}</p>

                                { /* TODO remove inline styles */ }
                                {to && <ReadMore style={{ paddingTop: '10px' }} url={to} content='Подробнее' />}
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>)
}

type CarouselProps = {
    items?: CarouselItemProps[]
}

const Carousel: React.FC<CarouselProps> = ({ items }) => (
    <div className="col-md-12 animate-box">
        <div className="owl-carousel owl-carousel2 project-wrap">
            {items && items.map((item, index) => <CarouselItem key={index} index={index + 1} {...item} />)}
        </div>
    </div>
)

const Project: React.FC = () => (
    <div id="ui-project">
        <div className="container">
            <div className="row">
                <div className="col-md-4 animate-box ui-heading animate-box">
                    <span className="sm">Сводка</span>
                    <h2>
                        <span className="thin">Сезон</span>{" "}
                        <span className="thick">2020</span>
                    </h2>
                    <p>
                        В период навигации 2020 года платформа «Федор Ушаков» совместно с ООО «ИнжГео» будет участвовать в выполнении комплексных инженерных изысканий для нужд ПАО «Газпром» в акватории Обской губы на объектах «Северо-каменномысское море» и «Каменномысское море».
                    </p>
                </div>
                <div className="col-md-7 col-md-push-1">
                    <div className="row">
                        <Carousel items={items} />
                    </div>
                </div>
            </div>
        </div>
    </div>
)


const Specs = () => {
    return (
        <Project />
    )
}



export default Specs